module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FF0026","theme_color":"transparent","display":"minimal-ui","icon":"/src/images/favicon.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4cc125d4be1aba7c0585cbc0214e852b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"rum":{"applicationId":"10b4cde6-7673-4f41-b9da-e72eaf3e832f","clientToken":"pub5aedd37e3ff584797bc06d94d27797aa","site":"datadoghq.com","service":"storefront","sampleRate":100,"enabled":true,"version":"US-f08e023ba522c6bd075f9d227996c6917609d931","trackInteractions":true,"trackSessionAcrossSubdomains":true,"actionNameAttribute":"data-event"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-64891632-6","head":true,"anonymize":true,"respectDNT":false,"pageTransitionDelay":0,"siteSpeedSampleRate":100,"cookieDomain":"whoop.com","allowLinker":true,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NGFF9PP","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://67ae0a3908e442e7ae3fb0014a61bbf9@o217231.ingest.sentry.io/5972648","tunnel":"https://api.prod.whoop.com/sentry-proxy-service/v1/exception/","sampleRate":1,"tracesSampleRate":0,"release":"US-f08e023ba522c6bd075f9d227996c6917609d931"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"languages","languages":["en"],"defaultLanguage":"en","i18nextOptions":{"interpolation":{"escapeValue":false},"debug":false,"saveMissing":true}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
